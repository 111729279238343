import {
  Button,
  FormControl,
  FormLabel,
  IconButton,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { FileCopy } from '@material-ui/icons';
import React, { FC, useEffect, useState } from 'react';

import { createToken } from 'api/utility/authentication';
import Alert, { useAlert } from 'components/shared/Alert/Alert';
import { OrganizationItemLink } from 'models';

import './UserTokenView.scss';

export interface UserTokenViewProps {
  userName: string;
  organizations: OrganizationItemLink[];
  createdToken: string;
  setCreatedToken: React.Dispatch<React.SetStateAction<string>>;
}

export const UserTokenView: FC<UserTokenViewProps> = ({
  userName,
  organizations,
  createdToken,
  setCreatedToken,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [label, setLabel] = useState<string | null>(null);
  const [tenantId, setTenantId] = useState<string | null>(
    organizations[0] ? organizations[0].id : null
  );

  const { isAlertOpen, alertMessage, openAlert, closeAlert, variant } =
    useAlert();

  const handleCreateToken = async () => {
    if (!label) {
      return openAlert('Label is required', 'error');
    }
    if (!tenantId) {
      return openAlert('Please select a tenant', 'error');
    }

    const createTokenBody = {
      tenantId,
      userName,
      label,
    };

    setIsLoading(true);
    const generatedToken = await createToken(createTokenBody);
    setIsLoading(false);

    if (generatedToken) {
      setCreatedToken(generatedToken);
    } else {
      openAlert('Error occurred while trying to create token', 'error');
    }
  };
  const handleCopyToken = async () => {
    await navigator.clipboard.writeText(createdToken);
    setLabel('');
    setCreatedToken('');
    openAlert('Token copied successfully', 'success');
  };

  useEffect(() => {
    if (createdToken === '') {
      setLabel('');
    }
  }, [createdToken]);

  return (
    <div className="UserTokenView">
      {createdToken ? (
        <div className="CopyToken">
          <div className="warning">
            WARNING: Once token is copied or you leave this screen the token
            will no longer be available to you. Please store it safely.
          </div>
          <div className="token-box">
            <div className="token">{createdToken}</div>
            <IconButton
              className="copy-button"
              aria-label="Copy"
              onClick={handleCopyToken}
            >
              <FileCopy />
            </IconButton>
          </div>
        </div>
      ) : (
        <div className="CreateToken">
          <FormControl variant="outlined">
            <FormLabel>Label</FormLabel>
            <TextField
              type="text"
              variant="outlined"
              size="small"
              value={label}
              onChange={(e) => setLabel(e.target.value)}
            />
          </FormControl>

          <FormControl variant="outlined" className="orgSelect">
            <FormLabel>Organization</FormLabel>
            <Select
              value={tenantId}
              onChange={(e) => setTenantId(e.target.value as string)}
            >
              {organizations.map((organization) => (
                <MenuItem key={organization.id} value={organization.id}>
                  {organization.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Button
            className="submit"
            onClick={handleCreateToken}
            variant="contained"
            color="secondary"
            style={{
              color: 'white',
            }}
            disabled={isLoading}
          >
            Create Token
          </Button>
        </div>
      )}
      <Alert
        open={isAlertOpen}
        variant={variant}
        message={alertMessage}
        onClose={closeAlert}
        duration={4500}
      />
    </div>
  );
};
