export enum FolioDocumentTemplateTypeName {
  BUILD_YOUR_CAR_DEAL = 'BUILD_YOUR_CAR_DEAL',
  BUYING_ONLINE = 'BUYING_ONLINE',
  CALCULATE_PAYMENTS = 'CALCULATE_PAYMENTS',
  CARFAX = 'CARFAX',
  CPO_INSPECTION = 'CPO_INSPECTION',
  CPO_WARRANTY = 'CPO_WARRANTY',
  DEALER_ADDED_MEDIA = 'DEALER_ADDED_MEDIA',
  DEALER_RATER = 'DEALER_RATER',
  EASY_PRE_QUALIFY = 'EASY_PRE_QUALIFY',
  EXTENDED_SERVICE = 'EXTENDED_SERVICE',
  GET_FINANCED = 'GET_FINANCED',
  GOOGLE_REVIEWS = 'GOOGLE_REVIEWS',
  MEET_THE_GM = 'MEET_THE_GM',
  NEW_INSPECTION = 'NEW_INSPECTION',
  NEW_WARRANTY = 'NEW_WARRANTY',
  OEM_BROCHURE = 'OEM_BROCHURE',
  OEM_VIDEO = 'OEM_VIDEO',
  OUR_PROCESS = 'OUR_PROCESS',
  OUR_STAFF = 'OUR_STAFF',
  PAST_SERVICES = 'PAST_SERVICES',
  RETURN_POLICY = 'RETURN_POLICY',
  SERVICE_COUPONS = 'SERVICE_COUPONS',
  SERVICE_INTRO = 'SERVICE_INTRO',
  USED_INSPECTION = 'USED_INSPECTION',
  USED_WARRANTY = 'USED_WARRANTY',
  VALUE_YOUR_TRADE = 'VALUE_YOUR_TRADE',
  VIDEO_WALKTHROUGH = 'VIDEO_WALKTHROUGH',
  WHY_BUY = 'WHY_BUY',
  WINDOW_STICKER = 'WINDOW_STICKER',
}

export interface FolioDocumentTemplateType {
  id: string;
  name: string;
  type: string;
  defaultDisplayLabel: string;
  description: string;
  defaultPreviewImageUrl: string;
}

export interface FolioDocumentTemplate {
  id: string;
  name: string;
  type: string;
  url: string | null;
  thumbnailUrl: string;
  originalThumbnailUrl?: string;
  displayLabel: string;
  uiKey?: string;
}

export enum FolioTemplateRootType {
  ROOT_ALL = 'ROOT_ALL',
  ROOT_CPO = 'ROOT_CPO',
  ROOT_CUSTOM = 'ROOT_CUSTOM',
  ROOT_NEW = 'ROOT_NEW',
  ROOT_DEFAULT = 'ROOT_DEFAULT',
  ROOT_USED = 'ROOT_USED',
}

export enum FolioTemplateFolderType {
  DEALER_REPUTATION = 'Dealer Reputation',
  FEATURED = 'Featured',
  FINANCIAL_TOOLS = 'Financial Tools',
  VEHICLE_REPUTATION = 'Vehicle Reputation',
}

export interface FolioFolder {
  id: string;
  name: string;
  documents: FolioDocumentTemplate[];
  sortOrder: number;
}

export interface FolioTemplateType {
  id: string;
  name: string;
  defaultDisplayLabel: string;
}

export interface FolioTemplate {
  id: string;
  tenantId: string;
  type: string;
  folders: FolioFolder[];
  settings: Partial<{
    ADDITIONAL_PRICES: string;
    PRICE_LABEL: string;
    PRICE_TYPE: string;
    SHOW_SNAPCELL_VIDEOS: string;
    VEHICLE_SPECS_ORDER: string;
    RECOMMENDED_VEHICLE_ORDER: string;
  }>;
  availableDocuments: FolioDocumentTemplate[];
  make?: string;
}

export interface FolioTemplateChild {
  folioFolder: FolioTemplate;
  order: number;
}

export interface FolioTemplateListOption {
  id: string;
  type: string;
  tenantId: string;
  v1: boolean;
  make?: string;
}

export interface FolioTemplateMatchRule {
  id: string;
  tenantId: string;
  folioFolderTemplateId: string;
  ordinal: number;
  matchRule: string; // Example: CONDITION::USED,VEHICLE_MAKE::Acura,VEHICLE_YEAR::2002,VEHICLE_YEAR::2004-2009
}
