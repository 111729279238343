import { Checkbox } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Create, Delete, Launch } from '@material-ui/icons';
import Skeleton from '@material-ui/lab/Skeleton';
import { memo } from 'react';
import { areEqual } from 'react-window';

import useSession from 'api/session';
import PERMISSIONS from 'common/permissions';
import { AuthenticationResponse, User } from 'models';
import utils from 'utils';
import time from 'utils/time';

import { Column, RowProps } from './types';

import './UsersList.scss';

const ROW_HEIGHT = 30;

const getColumnData = (
  session: AuthenticationResponse | undefined,
  user: User,
  column: Column,
  editUserDialog?: (openDialog: boolean, selectedUserId?: string) => void,
  openUserSessionDialog?: (
    openDialog: boolean,
    selectedUserId?: string
  ) => void,
  onDeleteUser?: (user: User) => void,
  onCheckUser?: (user: User) => void,
  checkedUsers?: { [key: string]: boolean }
) => {
  const editPermission = session?.permissions.includes(
    PERMISSIONS.USERS_UPDATE
  );
  // const selfEditPermission = session?.permissions.includes(PERMISSIONS.USERS_SELF_UPDATE);
  const deletePermission = session?.permissions.includes(
    PERMISSIONS.USERS_DELETE
  );
  // const selfDeletePermission = session?.permissions.includes(PERMISSIONS.USERS_SELF_DELETE);
  const sessionsPermission = session?.permissions.includes(
    PERMISSIONS.USERS_SESSIONS
  );

  if (column.dataKey === 'actionButtons') {
    return (
      <>
        {deletePermission && (
          <Delete className="icon" onClick={async () => onDeleteUser?.(user)} />
        )}
        {editPermission && editUserDialog && (
          <Create
            className="icon"
            onClick={() => editUserDialog(true, user?.id)}
          />
        )}
        {sessionsPermission && openUserSessionDialog && (
          <Launch
            className="icon"
            onClick={() => openUserSessionDialog(true, user?.id)}
          />
        )}
      </>
    );
  }

  if (column.dataKey === 'fullName') {
    return `${user.firstName || ''} ${user.lastName || ''}`;
  }

  if (column.dataKey === 'phone') {
    return user?.mobilePhone?.number;
  }

  if (column.dataKey === `lastSession`) {
    if (user.lastSession) {
      return time.formatTimestamp(new Date(user.lastSession));
    }
    return ``;
  }

  if (column.dataKey === 'lastActivity') {
    if (user.lastActivity) {
      return time.formatTimestamp(new Date(user.lastActivity));
    }
    return '';
  }

  if (column.dataKey === 'checkbox') {
    return (
      <Checkbox
        checked={checkedUsers?.[user.id]}
        onClick={() => onCheckUser?.(user)}
      />
    );
  }

  const userProperty = column.dataKey;
  return user[userProperty];
};

const UsersListRow = memo(
  ({
    style,
    index,
    user,
    editUserDialog,
    openUserSessionDialog,
    headerColumns,
    onDeleteUser,
    onCheckUser,
    checkedUsers,
  }: RowProps) => {
    const { data: session } = useSession();

    // const { onClickAction: handleEditClick, permission: editPermission } = editUserProcess; // TODO handle permissions the same and not over engineered objects

    return (
      <TableRow component="div" className="row" style={style} key={index}>
        {headerColumns.map((column: Column) => {
          if (!user) {
            return (
              <Skeleton
                className="loadingSkeleton"
                height={ROW_HEIGHT}
                key={`${utils.strings.createRandomCharacters()}skeleton`}
              />
            );
          }

          return (
            <TableCell
              key={`${user.id ?? 'emptyKey'}${column.dataKey}`}
              component="div"
              variant="body"
              align={column.dataKey === 'actionButtons' ? 'right' : 'left'}
              className={`cell ${
                column.dataKey === 'actionButtons' ? 'actionButtons' : ''
              }`}
              style={{
                minWidth: column.width,
                maxWidth: column.maxWidth ?? undefined,
                height: ROW_HEIGHT,
              }}
            >
              {getColumnData(
                session,
                user,
                column,
                editUserDialog,
                openUserSessionDialog,
                onDeleteUser,
                onCheckUser,
                checkedUsers
              )}
            </TableCell>
          );
        })}
      </TableRow>
    );
  },
  areEqual
);

export default UsersListRow;
