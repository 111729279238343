import {
  Button,
  DialogActions,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import { useCallback, useEffect, useState } from 'react';
import { AutoSizer } from 'react-virtualized';
import { FixedSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

import { useClearUserSessions, useUserSessions } from 'api/users';
import ConfirmationDialog, {
  useConfirmationDialog,
} from 'components/shared/ConfirmationDialog';
import Dialog from 'components/shared/Dialog';
import {
  ListItemProps,
  ListRendererProps,
} from 'components/shared/UsersList/types';
import { UserSession } from 'models';

import { HeaderProps } from './types';
import UserSessionListHeader from './UserSessionListHeader';
import UserSessionListRow from './UserSessionListRow';

import './UserSessionDialog.scss';

const ROW_HEIGHT = 30;
const HEADERS: HeaderProps[] = [
  { name: 'Login Source', width: '70px' },
  { name: 'Created At', width: '130px' },
  { name: 'Updated At', width: '130px' },
  { name: 'OS', width: '60px' },
  { name: 'Browser', width: '60px' },
  { name: 'Device', width: '60px' },
  { name: 'IP Address', width: '60px' },
  { name: 'Num Times Refreshed', width: '40px' },
];

const useScrollBarDetector = (heightOfContent: number): boolean => {
  const [isScrollbar, setIsScrollbar] = useState(false);

  useEffect(() => {
    const { innerHeight: windowHeight } = window;
    if (heightOfContent > windowHeight) {
      setIsScrollbar(true);
    } else {
      setIsScrollbar(false);
    }
  }, [heightOfContent]);

  return isScrollbar;
};

interface UserSessionDialogProps {
  onClose: () => void;
  userId?: string;
}

const UserSessionDialog = (props: UserSessionDialogProps) => {
  const { onClose, userId } = props;
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useUserSessions(userId);
  const {
    isConfirmationDialogOpen,
    isConfirmationLoading,
    confirmationMessage,
    primaryButtonLabel,
    onAccept,
    onDeny,
    openConfirmationDialog,
  } = useConfirmationDialog();
  const { mutateAsync: clearUserSessions } = useClearUserSessions(userId);

  const userSessions =
    data?.pages?.reduce(
      (acc: UserSession[], page: any) => [...acc, ...page.data.list],
      []
    ) ?? [];

  const isItemLoaded = (index: number) =>
    !hasNextPage || index < userSessions.length;

  const loadMoreItems = useCallback(
    (_startIndex: number, stopIndex: number) => {
      if (userSessions.length < stopIndex && !isFetchingNextPage) {
        return fetchNextPage();
      }
      return Promise.resolve();
    },
    [fetchNextPage, userSessions.length, isFetchingNextPage]
  );

  const itemCount = data?.pages[0].totalItems || userSessions.length;
  const isScrollbarPresent = useScrollBarDetector(itemCount * ROW_HEIGHT);

  const onClickClearUserSessions = () =>
    openConfirmationDialog({
      messageOverride: `Are you sure you want to clear all ${itemCount} active user sessions`,
      primaryButtonLabelOverride: 'Yes',
      secondaryButtonLabelOverride: 'No',
      onAcceptOverride: async () => await clearUserSessions(),
    });

  return (
    <>
      <Dialog
        open
        className="UserSessionDialog"
        fullWidth
        hidePrimaryButton
        hideCancelButton
        maxWidth="lg"
      >
        <DialogTitle>
          <Typography>{`User Sessions for ${userId} - (${itemCount} active sessions)`}</Typography>
        </DialogTitle>

        <div className="UserSessionList">
          <div className="flex-grow">
            <div className="flex-columns UserSessionList-table">
              <Table className="table" component="div">
                <UserSessionListHeader
                  isScrollbarPresent={isScrollbarPresent}
                  rowHeight={ROW_HEIGHT}
                  headers={HEADERS}
                />
                <TableBody component="div" className="full-height">
                  <AutoSizer>
                    {({ height, width }: ListRendererProps) => (
                      <InfiniteLoader
                        isItemLoaded={isItemLoaded}
                        itemCount={itemCount}
                        loadMoreItems={loadMoreItems}
                      >
                        {({ onItemsRendered, ref }) => (
                          <FixedSizeList
                            className="list"
                            itemCount={userSessions.length}
                            height={height}
                            width={width}
                            itemSize={ROW_HEIGHT}
                            onItemsRendered={onItemsRendered}
                            ref={ref}
                          >
                            {({ index, style }: ListItemProps) => (
                              <UserSessionListRow
                                userSession={userSessions[index]}
                                rowHeight={ROW_HEIGHT}
                                headers={HEADERS}
                                style={style}
                              />
                            )}
                          </FixedSizeList>
                        )}
                      </InfiniteLoader>
                    )}
                  </AutoSizer>
                </TableBody>
              </Table>
            </div>
          </div>
        </div>

        <DialogActions>
          <Button onClick={onClose}>Close</Button>
          {itemCount > 0 && (
            <Button id="clear-user-sessions" onClick={onClickClearUserSessions}>
              {'End All Active Sessions'}
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        message={confirmationMessage}
        onPrimaryButtonClick={onAccept}
        onSecondaryButtonClick={onDeny}
        isLoading={isConfirmationLoading}
        primaryButtonLabel={primaryButtonLabel}
      />
    </>
  );
};

export default UserSessionDialog;
