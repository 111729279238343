import { useState } from 'react';

import { useUsers } from 'api/users';
import UsersList from 'components/shared/UsersList';

import EditUserDialog from './EditUserDialog';
import UserSessionDialog from './UserSessionDialog';

import './UsersView.scss';

const UsersView = () => {
  const [isEditUserDialogOpen, setIsEditUserDialogOpen] = useState(false);
  const [isUserSessionDialogOpen, setIsUserSessionDialogOpen] = useState(false);
  const [userId, setUserId] = useState<string>('');
  const [searchText, setSearchText] = useState('');
  const usersQueryData = useUsers(searchText);
  const editUserDialog = (openDialog: boolean, selectedUserId: string = '') => {
    setUserId(selectedUserId);
    setIsEditUserDialogOpen(openDialog);
  };
  const openUserSessionDialog = (
    openDialog: boolean,
    selectedUserId: string = ''
  ) => {
    setUserId(selectedUserId);
    setIsUserSessionDialogOpen(openDialog);
  };

  return (
    <>
      <div className="UsersView page-container">
        <UsersList
          usersQueryData={usersQueryData}
          setSearchText={setSearchText}
          editUserDialog={editUserDialog}
          openUserSessionDialog={openUserSessionDialog}
        />
        {isEditUserDialogOpen && (
          <EditUserDialog
            onClose={editUserDialog}
            userId={userId}
            refreshActionAfterSave={() => {
              /* TODO */
            }}
          />
        )}
        {isUserSessionDialogOpen && (
          <UserSessionDialog
            onClose={() => openUserSessionDialog(false)}
            userId={userId}
          />
        )}
      </div>
    </>
  );
};

export default UsersView;
