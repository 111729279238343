import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
} from '@tanstack/react-query';

import { defaultMetaQueryFn } from 'api/useAPI';
import { OrganizationHistory } from 'models';

export interface UseOrganizationHistoryParams {
  s?: string;
  userId?: string;
  startIndex?: number;
  pageSize?: number;
}

export interface GetOrganizationHistoryData {
  data: OrganizationHistory[];
  meta: {
    returnedRecords: number;
    totalRecords: number;
  };
}

type UseOrganizationHistory = (
  orgId: string,
  params: UseOrganizationHistoryParams
) => UseInfiniteQueryResult<GetOrganizationHistoryData, Error>;

const createOrganizationHistoryPath = (
  orgId: string,
  params: UseOrganizationHistoryParams
): string => {
  const startIndex = params.startIndex ?? 0;
  const pageSize = params.pageSize ?? 500;
  const searchParam = params.s ? `&s=${params.s}` : '';
  const userIdParam = params.userId ? `&userId=${params.userId}` : '';

  return `/orgs/${orgId}/history?startIndex=${startIndex}&pageSize=${pageSize}${searchParam}${userIdParam}`;
};

export const useOrganizationHistory: UseOrganizationHistory = (
  orgId,
  params
) => {
  let startIndex = params.startIndex ?? 0;
  let pageSize = params.pageSize ?? 500;

  const url = createOrganizationHistoryPath(orgId, params);

  const defaultPageParams = { startIndex, pageSize };

  return useInfiniteQuery<GetOrganizationHistoryData, Error>(
    [url],
    async ({ pageParam = defaultPageParams }) => {
      params = {
        ...params,
        startIndex: pageParam.startIndex,
        pageSize: pageParam.pageSize,
      };

      const path = createOrganizationHistoryPath(orgId, params);

      return defaultMetaQueryFn(path);
    },
    {
      getNextPageParam: (lastPage, pages) => {
        const totalHistoriesLoaded = pages.reduce(
          (acc, page) => acc + page.meta.returnedRecords,
          0
        );

        if (totalHistoriesLoaded === lastPage.meta.totalRecords) return;

        return {
          startIndex: pages.length * pageSize,
          pageSize: params.pageSize ?? 500,
        };
      },
      enabled: !!orgId,
    }
  );
};
