import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import { useChangePassword } from 'api/users';
import Alert, { useAlert } from 'components/shared/Alert';
import LoadingIndicator from 'components/shared/LoadingIndicator';

import './SetUserPasswordDialog.scss';

interface SetUserPasswordDialogProps {
  userId: string;
  onClose: () => void;
}

const SetUserPasswordDialog: React.FC<SetUserPasswordDialogProps> = ({
  userId,
  onClose,
}) => {
  const { isAlertOpen, alertMessage, variant, openAlert, closeAlert } =
    useAlert();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const { isLoading, isError, isSuccess, mutate } = useChangePassword(userId);

  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
  }, [isSuccess, onClose]);

  useEffect(() => {
    if (isError) {
      openAlert('An error occurred when setting the password.', 'error');
    }
  }, [isError, openAlert]);

  const onPrimary = async () => {
    if (password !== confirmPassword) {
      openAlert('Passwords do not match.', 'error');
    }
    mutate(password);
  };

  return (
    <>
      <Dialog open className="SetUserPasswordDialog" maxWidth="lg">
        <DialogTitle>Set User Password</DialogTitle>
        <DialogContent className="SetUserPasswordDialog-content">
          <TextField
            required
            className="form-control"
            label="Password"
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            margin="dense"
            type="password"
          />
          <TextField
            required
            className="form-control"
            label="Confirm Password"
            variant="outlined"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            margin="dense"
            type="password"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} disabled={isLoading}>
            Cancel
          </Button>
          <Button
            color="secondary"
            style={{ color: 'white' }}
            variant="contained"
            onClick={onPrimary}
            disabled={isLoading}
            startIcon={
              isLoading && <LoadingIndicator size={13} color="primary" />
            }
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Alert
        open={isAlertOpen}
        message={alertMessage}
        variant={variant}
        onClose={closeAlert}
        duration={3500}
      />
    </>
  );
};

export default SetUserPasswordDialog;
