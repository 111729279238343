import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import React from 'react';

import { HeaderProps } from './types';

interface UserSessionListHeaderProps {
  isScrollbarPresent: boolean;
  rowHeight: number;
  headers: HeaderProps[];
}

const UserSessionListHeader: React.FC<UserSessionListHeaderProps> = ({
  isScrollbarPresent,
  headers,
  rowHeight,
}) => {
  return (
    <TableHead
      component="div"
      className={`row header ${
        isScrollbarPresent ? 'scrollbarAdjustment' : ''
      }`}
    >
      {headers.map((header) => {
        return (
          <TableCell
            key={header.name}
            component="div"
            variant="head"
            align="left"
            className="cell header-cell"
            style={{
              minWidth: header.width,
              height: rowHeight,
            }}
          >
            {header.name}
          </TableCell>
        );
      })}
    </TableHead>
  );
};

export default UserSessionListHeader;
