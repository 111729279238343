import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TextField from '@material-ui/core/TextField';
import { debounce } from 'lodash';
import pluralize from 'pluralize';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

const ROW_HEIGHT = 30;
const SEARCH_DELAY_MS = 400;

interface UsersListHeaderProps {
  totalUsers: number;
  checkedUsers: number;
  setSearchText: (text: string) => void;
  isScrollbarPresent: boolean;
}

const UsersListHeader: React.FC<UsersListHeaderProps> = ({
  isScrollbarPresent,
  totalUsers,
  checkedUsers,
  setSearchText,
}) => {
  const [textFieldText, setTextFieldText] = useState('');
  const debouncedOnSearch = useMemo(
    () => debounce((text) => setSearchText(text), SEARCH_DELAY_MS),
    [setSearchText]
  );

  useEffect(() => {
    debouncedOnSearch(textFieldText);
  }, [debouncedOnSearch, textFieldText]);

  const handleSearchFieldChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setTextFieldText(event.target.value);
    },
    []
  );

  const displayText = useMemo(
    () => `${pluralize('User', totalUsers, true)}, ${checkedUsers} checked`,
    [checkedUsers, totalUsers]
  );

  return (
    <>
      <div className="CollectionHeaderBar">
        <div className="CollectionHeaderBar-text">{displayText}</div>
        <div className="CollectionHeaderBar-actions">
          <TextField
            variant="outlined"
            margin="dense"
            value={textFieldText}
            onChange={handleSearchFieldChange}
            placeholder="Search"
          />
        </div>
      </div>
      <TableHead
        component="div"
        className={`row header ${
          isScrollbarPresent ? 'scrollbarAdjustment' : ''
        }`}
      >
        <TableCell
          component="div"
          variant="head"
          align="left"
          className="cell header-cell"
          style={{
            minWidth: '200px',
            height: ROW_HEIGHT,
          }}
        >
          Name
        </TableCell>
        <TableCell
          component="div"
          variant="head"
          align="left"
          className="cell header-cell"
          style={{
            minWidth: '100px',
            height: ROW_HEIGHT,
          }}
        >
          Email
        </TableCell>
        <TableCell
          component="div"
          variant="head"
          align="right"
          className="cell header-cell actionButtons"
          style={{
            minWidth: '80px',
            height: ROW_HEIGHT,
          }}
        />
      </TableHead>
    </>
  );
};

export default UsersListHeader;
