import { useState } from 'react';

import { useOrganizationUsers } from 'api/organizations';
import OrgUsersList from 'components/shared/UsersList/OrgUsersList';
import EditUserDialog from 'components/UsersView/EditUserDialog';
import UserSessionDialog from 'components/UsersView/UserSessionDialog';

import AddUserDialog from '../AddUserDialog';
import BulkUserDialog from '../BulkUserDialog/BulkUserDialog';

import './OrgUsersView.scss';

interface UsersViewProps {
  organizationId: string;
}

const OrgUsersView = ({ organizationId }: UsersViewProps) => {
  const [isAddUserDialogOpen, setIsAddUserDialogOpen] = useState(false);
  const [isBulkUserDialogOpen, setIsBulkUserDialogOpen] = useState(false);
  const [isEditUserDialogOpen, setIsEditUserDialogOpen] = useState(false);
  const [userId, setUserId] = useState<string>('');
  const [isUserSessionDialogOpen, setIsUserSessionDialogOpen] = useState(false);

  const openAddUserDialog = () => {
    setIsAddUserDialogOpen(true);
  };

  const closeAddUserDialog = () => {
    setIsAddUserDialogOpen(false);
  };

  const openBulkUserDialog = () => {
    setIsBulkUserDialogOpen(true);
  };

  const closeBulkUserDialog = () => {
    setIsBulkUserDialogOpen(false);
  };

  const editUserDialog = (openDialog: boolean, selectedUserId: string = '') => {
    setUserId(selectedUserId);
    setIsEditUserDialogOpen(openDialog);
  };

  const openUserSessionDialog = (
    openDialog: boolean,
    selectedUserId: string = ''
  ) => {
    setUserId(selectedUserId);
    setIsUserSessionDialogOpen(openDialog);
  };

  return (
    <>
      <div className="Organization-UsersView">
        <OrgUsersList
          fetchUsers={useOrganizationUsers}
          organizationId={organizationId}
          editUserDialog={editUserDialog}
          openAddUserDialog={openAddUserDialog}
          openBulkUserDialog={openBulkUserDialog}
          openUserSessionDialog={openUserSessionDialog}
        />
        {isAddUserDialogOpen && (
          <AddUserDialog
            organizationId={organizationId}
            onClose={closeAddUserDialog}
          />
        )}
        {isEditUserDialogOpen && (
          <EditUserDialog
            onClose={editUserDialog}
            userId={userId}
            organizationId={organizationId}
          />
        )}
        {isBulkUserDialogOpen && (
          <BulkUserDialog
            organizationId={organizationId}
            onClose={closeBulkUserDialog}
          />
        )}
        {isUserSessionDialogOpen && (
          <UserSessionDialog
            onClose={() => openUserSessionDialog(false)}
            userId={userId}
          />
        )}
      </div>
    </>
  );
};

export default OrgUsersView;
