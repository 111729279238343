import { configuredFetch } from 'api/base';
import { ONE_YEAR_IN_SECONDS } from 'common/constants';

interface CreateTokenResponse {
  token: string;
}

interface CreateTokenRequest {
  label: string;
  tenantId: string;
  userName: string;
}

/**
 * Create JWT Token
 * Endpoint: POST /system/utility/authentication/thirdPartyToken
 */

export async function createToken(
  body: CreateTokenRequest
): Promise<string | null> {
  const url = '/system/utility/authentication/thirdPartyToken';

  try {
    const { data } = await configuredFetch<CreateTokenResponse>(url, {
      method: 'POST',
      body: JSON.stringify({
        ...body,
        timeoutInSeconds: ONE_YEAR_IN_SECONDS,
      }),
    });

    return data.token;
  } catch (error) {
    return null;
  }
}
