import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';

import { UserSession } from 'models';
import utils from 'utils';

import { HeaderProps } from './types';

interface UserSessionListRowProps {
  userSession: UserSession;
  rowHeight: number;
  headers: HeaderProps[];
  style: any;
}

const UserSessionListRow: React.FC<UserSessionListRowProps> = ({
  userSession,
  headers,
  rowHeight,
  style,
}) => {
  if (!userSession) {
    return (
      <Skeleton
        className="loadingSkeleton"
        height={rowHeight}
        key={`${utils.strings.createRandomCharacters()}skeleton`}
      />
    );
  }

  const rowData = [
    userSession.loginSource,
    userSession.createdAt,
    userSession.updatedAt,
    userSession.os,
    userSession.browser,
    userSession.device,
    userSession.ipAddress,
    userSession.numTimesRefreshed,
  ];

  return (
    <TableRow
      component="div"
      className="row"
      style={style}
      key={userSession?.id}
    >
      {rowData.map((value, index) => {
        return (
          <TableCell
            key={index}
            component="div"
            variant="body"
            align="left"
            className="cell"
            style={{
              minWidth: headers[index].width,
              height: rowHeight,
            }}
          >
            {value}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default UserSessionListRow;
